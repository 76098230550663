header {
    color: white;
    padding: 1em 0;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
    margin-right: 1em;
  }
  
  nav ul li a {
    text-decoration: none;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }
  