.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
}

.project-card {
  position: relative;
  background: #e6e6e6;
  padding: 3rem;
  border-radius: 15px;
  width: 25vw;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 105%;
    height: 105%;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 12px;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: -1;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  }

  &:hover {
    transform: scale(1.05);

    &::before {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  h3 {
    color: black;
    margin-bottom: 0.5rem;
    font-size: 150%;
  }

  a {
    color: #3dc736;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    font-size: 150%;

    &:hover {
      color: #2c8d27;
    }
  }

  .repo-description {
    color: #191919;
    margin-top: 1rem;

    h1, h2, h3, h4, h5, h6 {
      color: #191919;
    }

    p {
      color: #191919;
    }

    a {
      color: #3dc736;
      text-decoration: underline;

      &:hover {
        color: #2c8d27;
      }
    }

    code {
      color: #ff5733;
    }

    pre {
      background-color: #f0f0f0;
      color: #333;
      padding: 1rem;
      border-radius: 4px;
    }

    blockquote {
      border-left: 2px solid #ddd;
      padding-left: 1rem;
      color: #666;
    }
  }

  .project-languages {
    text-align: left;
    margin-top: 1rem;

    .languages-bar {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
    }

    .language-bar-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .language-name {
      font-size: 2.5vh;
      margin-bottom: 0.25rem;
      color: #000000;
      text-align: left;
    }

    .language-bar {
      position: relative;
      background: #e1e4e8;
      border-radius: 5px;
      height: 1.5rem;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      overflow: hidden;
      box-sizing: border-box;

      .language-bar-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #4CAF50;
        border-radius: 5px;
        z-index: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .project-card {
    width: 90%;
    max-width: none;
  }

  h3 {
    font-size: 4vh;
  }

  a {
    font-size: 2.5vh;
  }

  .language-name {
    font-size: 2vh;
  }

  .repo-description {
    font-size: 2vh;
  }
}

@media (max-width: 480px) {
  .project-card {
    width: 100%;
    padding: 1.5rem;
  }

  h3 {
    font-size: 3vh;
  }

  a {
    font-size: 2vh;
  }

  .language-name {
    font-size: 1.8vh;
  }

  .repo-description {
    font-size: 1.8vh;
  }
}
